import {
  Group,
  Text,
  Title,
  UnstyledButton,
  Button,
  useMantineTheme,
  Stack,
  Flex,
} from '@mantine/core';
import { FC, useMemo, useReducer } from 'react';
import { Check, Warning } from '@phosphor-icons/react';
import { formatPenceToPounds } from 'utils/formatPrice';
import { BasketApplyPromotion } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { PreCheckoutBasket, PreCheckoutBasketTicket } from 'interfaces';
import { PromoErrorsEnum, SubscriptionTrialTypeEnum, ActivityTypeEnum } from 'enums';
import { trackAction, Actions } from 'utils/amplitude';
import { PebbleTextInput } from 'components/ui';
import classes from './SessionCost.module.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';
import getBasket from 'graphql/queries/getBasket';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import BlockTrialsInfoSummary from './BlockTrialsInfoSummary/BlockTrialsInfoSummary';
dayjs.extend(customParseFormat);

export interface ISessionCostProps {
  userToken?: string;
  tickets: PreCheckoutBasketTicket[];
  basket: PreCheckoutBasket;
  trialEndDate?: {
    date: string;
    month?: string;
    cancellationDate?: string;
    hasPostTrialSessions?: boolean;
  };
  isBlockTrialCheckout: boolean;
  blockTrialPriceSum: string;
}
type State = {
  discountCode: string;
  discountError: string;
  discountCodeValid: boolean;
  removingPromo: boolean;
  loading: boolean;
};

const initialState = {
  discountCode: '',
  discountError: '',
  discountCodeValid: false,
  removingPromo: false,
  loading: false,
};

type Action =
  | { type: 'VALID'; payload: { discount: number; finalAmount: number } }
  | { type: 'ERROR'; payload: string }
  | { type: 'CHANGE'; payload: string }
  | { type: 'REMOVE' }
  | { type: 'APPLYING' };

const VALID = 'VALID';
const ERROR = 'ERROR';
const CHANGE = 'CHANGE';
const REMOVE = 'REMOVE';
const APPLYING = 'APPLYING';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case VALID:
      return {
        ...state,
        removingPromo: false,
        loading: false,
      };
    case ERROR:
      return {
        ...state,
        discountError: action.payload,
        discountCodeValid: false,
        loading: false,
      };
    case CHANGE:
      return {
        ...state,
        discountError: '',
        discountCode: action.payload,
        discountCodeValid: action.payload.length > 2,
      };
    case REMOVE:
      return {
        ...initialState,
        removingPromo: true,
      };
    case APPLYING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

let changeTimeout: ReturnType<typeof setTimeout>;

const SessionCost: FC<ISessionCostProps> = ({
  userToken,
  tickets,
  basket,
  trialEndDate,
  isBlockTrialCheckout,
  blockTrialPriceSum,
}) => {
  const theme = useMantineTheme();
  const { promotion, finalAmount, originalAmount, discount, activity } = basket;

  const isSubscription = activity.activityType === ActivityTypeEnum.SUBSCRIPTION;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    discountCode: promotion?.code,
    discountCodeValid: Boolean(promotion?.code && discount),
  });
  const { discountCode, discountCodeValid, discountError, removingPromo, loading } = state;

  const baseVariables = useMemo(
    () => ({
      id: basket.id,
    }),
    [basket.id],
  );

  const totalCost = useMemo(() => {
    return finalAmount ?? originalAmount;
  }, [finalAmount, originalAmount]);

  const isFirstOfMonth = dayjs().date() === 1;

  const hasTrial = useMemo(() => {
    if (!isSubscription) {
      return false;
    }
    return tickets.some((ticket) => ticket.subscriptionTrialSelected);
  }, [isSubscription, tickets]);

  const trialPayment = useMemo(() => {
    if (!isSubscription) {
      return null;
    }

    if (hasTrial && activity.subscriptionTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL) {
      return 'Free';
    }
    if (hasTrial && activity.subscriptionTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL) {
      return formatPenceToPounds(activity.subscriptionTrialPrice || 0);
    }

    return null;
  }, [isSubscription, activity, hasTrial]);

  const subscriptionStartDate = useMemo(() => {
    if (!isSubscription) {
      return undefined;
    }
    const startDateString = activity.subscriptionStart;
    if (!startDateString) {
      return undefined;
    }
    const dayjsObj = dayjs(startDateString);
    return {
      date: dayjsObj.format('DD/MM/YYYY'),
      month: dayjsObj.format('MMMM'),
    };
  }, [isSubscription, activity.subscriptionStart]);

  const getFirstFullPaymentMonth = () => {
    if (!trialEndDate?.date) return;

    const parsedDate = dayjs(trialEndDate.date, 'DD-MM-YYYY');

    return parsedDate.add(1, 'M').format('MMMM');
  };

  const [basketApplyPromotion] = useMutation(BasketApplyPromotion, {
    ...(userToken && {
      context: {
        headers: {
          Authorization: `${userToken}`,
        },
      },
    }),
    refetchQueries: [getBasket],
    onCompleted: (data) => {
      if (!data) {
        return;
      }
      const promoData = data.basketApplyPromotion;
      dispatch({
        type: VALID,
        payload: {
          discount: promoData?.discount,
          finalAmount: promoData?.finalAmount,
        },
      });
      if (discount) {
        trackAction(Actions.ACTIVITY_PROMO_CODE_VALID);
      }
      if (changeTimeout) {
        clearTimeout(changeTimeout);
      }
    },
    onError: (e) => {
      const message = Object.values<string>(PromoErrorsEnum).includes(e.message)
        ? e.message
        : 'Code not valid';
      dispatch({ type: ERROR, payload: message });
      console.log(e.message, 'error');
      trackAction(Actions.ACTIVITY_PROMO_CODE_INVALID);
    },
  });

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: CHANGE, payload: e.target.value });
  };

  const removeCode = () => {
    dispatch({ type: REMOVE });

    basketApplyPromotion({
      variables: {
        input: {
          promotion: null,
          ...baseVariables,
        },
      },
    });
  };

  const addCode = () => {
    dispatch({ type: APPLYING });
    basketApplyPromotion({
      variables: {
        input: {
          promotion: discountCode,
          ...baseVariables,
        },
      },
    });
  };

  return (
    <section>
      {originalAmount > 0 && !isSubscription && (
        <>
          <Flex
            justify="space-between"
            className={classNames(classes.sessionCostSection, {
              [classes.discountWrapper]: discount > 0,
            })}
          >
            <Flex align="center">
              {!discount && (
                <PebbleTextInput
                  label="Discount code"
                  placeholder="Insert your code here"
                  size="md"
                  onChange={handleChange}
                  value={discountCode || ''}
                  error={discountError}
                  classNames={{
                    input: classes.discountInput,
                    label: classes.discountLabel,
                    error: classes.discountError,
                    section: classes.rightSection,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      addCode();
                    }
                  }}
                />
              )}
              {discountCode && discount === 0 && (
                <Button onClick={addCode} disabled={!discountCode} loading={loading} mt="xs">
                  Apply code
                </Button>
              )}
              {discount > 0 && discountCodeValid && (
                <>
                  <Flex align="center">
                    <Text
                      className={classes.discountCode}
                      c={theme.colors.blue[8]}
                      fw={700}
                      mr="xs"
                    >
                      {discountCode}
                    </Text>
                    <Check weight="bold" size={18} color={theme.colors.blue[6]} />
                  </Flex>
                  <UnstyledButton onClick={removeCode} className={classes.removeCode} ml="lg">
                    Remove code
                  </UnstyledButton>
                </>
              )}
            </Flex>

            {!removingPromo && discountCode && (
              <Text className={classes.price} fw={700}>
                {discount > 0 ? '-' : ''}
                {discount === 0 ? '' : formatPenceToPounds(discount)}
              </Text>
            )}
          </Flex>
        </>
      )}

      {isSubscription && hasTrial && (
        <>
          <Group className={classes.subsInfoBox} wrap="nowrap" align="flex-start" my="md" gap="sm">
            <Warning
              color={theme.colors.yellow[6]}
              className={classes.warningIcon}
              weight="fill"
              size={64}
            />
            <Text size="xs" c={theme.colors.gray[6]} fw={600}>
              <b>Due after trial ends:</b> Your trial ends on {trialEndDate?.date}. On this date you
              will be charged a prorated amount based on the remaining days of {trialEndDate?.month}
              . This is what you’ll pay so you can attend the activity in {trialEndDate?.month}{' '}
              before paying for the full subscription on the 1st of {getFirstFullPaymentMonth()}.
              You will then be charged on the 1st of each month moving forward.
            </Text>
          </Group>

          <div className={classNames(classes.sessionCostSection, classes.totalPrice)}>
            <Stack gap={2}>
              <Title order={5} mb={0}>
                Total to pay now
              </Title>
              <Text fw={600}>
                (for {activity.subscriptionTrialSessionCount} trial session
                {(activity.subscriptionTrialSessionCount || 0) > 1 ? 's' : ''})
              </Text>
            </Stack>
            <Text
              className={classNames(classes.price, {
                [classes.freeTotal]: totalCost === 0 || trialPayment === 'Free',
              })}
              fw={700}
              data-testid="total-cost"
            >
              {trialPayment}
            </Text>
          </div>
        </>
      )}
      {isSubscription && subscriptionStartDate && !hasTrial && (
        <Group className={classes.subsInfoBox} wrap="nowrap" align="flex-start" my="md" gap="sm">
          <Warning
            color={theme.colors.yellow[6]}
            className={classes.warningIcon}
            weight="fill"
            size={64}
          />
          <Stack gap="xs">
            <Text size="xs" c={theme.colors.gray[6]} fw={600}>
              This subscription does not begin until {subscriptionStartDate.date} - the first
              payment for this activity will be taken on that date.
            </Text>
            <Text size="xs" c={theme.colors.gray[6]} fw={600}>
              We’ll calculate the amount you need to pay on {subscriptionStartDate.date} for the
              remaining days of {subscriptionStartDate.month}. This is what you’ll pay so you can
              join the activity in {subscriptionStartDate.month}.
            </Text>
          </Stack>
        </Group>
      )}
      {isSubscription && !hasTrial && !isFirstOfMonth && !subscriptionStartDate && (
        <Group className={classes.subsInfoBox} wrap="nowrap" align="flex-start" my="md" gap="sm">
          <Warning
            color={theme.colors.yellow[6]}
            className={classes.warningIcon}
            weight="fill"
            size={64}
          />
          <Text size="xs" c={theme.colors.gray[6]} fw={600}>
            <b>Due now:</b> On the next page we’ll calculate the amount you need to pay now for the
            rest of this month. This is what you’ll pay now so you can join the activity this month.
          </Text>
        </Group>
      )}
      {isBlockTrialCheckout &&
        basket.tickets[0].blockTrialType &&
        trialEndDate?.hasPostTrialSessions && (
          <BlockTrialsInfoSummary
            blockTrialType={basket.tickets[0].blockTrialType}
            ticketCapacity={basket.tickets[0].ticketCapacity}
            blockTrialPriceSum={blockTrialPriceSum}
            trialCancellationDate={trialEndDate?.cancellationDate}
          />
        )}
    </section>
  );
};

export default SessionCost;
