import {
  Modal,
  Button,
  Text,
  Box,
  Group,
  useMantineTheme,
  Title,
  Stack,
  Center,
} from '@mantine/core';
import { trackAction, Actions } from 'utils/amplitude';
import { calculateAgeInYearsAndMonths } from 'components/CheckoutDetails/CheckoutDetailsUtils';
import { useMediaQuery } from '@mantine/hooks';
import { ExclamationMark } from '@phosphor-icons/react';

import classes from './AgeCheckModal.module.scss';
import { Activity, Child } from 'types';
import { PreCheckoutBasketAttendee } from 'interfaces';

interface IAgeCheckModalProps {
  opened: boolean;
  onClose(): void;
  handleConfirmAttendees(): Promise<void>;
  attendeesOutOfAgeRange: (Child | PreCheckoutBasketAttendee | undefined)[];
  activity: Activity;
}

const AgeCheckModal: React.FC<IAgeCheckModalProps> = ({
  opened,
  onClose,
  handleConfirmAttendees,
  attendeesOutOfAgeRange,
  activity,
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  return (
    <Modal
      onClose={onClose}
      opened={opened}
      withCloseButton={false}
      centered
      size={640}
      fullScreen={isMobile}
      classNames={{
        content: classes.modalContent,
      }}
    >
      <Box
        style={{
          textAlign: 'center',
          padding: '24px',
        }}
      >
        <Center
          style={{
            width: '64px',
            height: '64px',
            borderRadius: '100px',
            backgroundColor: theme.colors.yellow[5],
            margin: '0 auto',
            marginBottom: '24px',
          }}
        >
          <ExclamationMark size={100} color="white" />
        </Center>
        <Title order={4} className={classes.modalTitle} mb="md">
          Age Check
        </Title>
        <Text fw={500} size="md" c={theme.colors.blue[8]} mb="sm">
          This activity is for attendees aged:
        </Text>
        <Text fw={500} size="md" c={theme.colors.blue[8]} mb="lg">
          {activity.ageRange}
        </Text>

        <Text fw={500} size="md" c={theme.colors.blue[8]} mb="sm">
          Attendee(s) outside of age range:
        </Text>
        <Stack gap={0} mb="lg">
          {attendeesOutOfAgeRange?.map((attendee) => (
            <Box key={attendee?.id} size="md" c={theme.colors.blue[8]}>
              <b>{attendee?.fullName}: </b>{' '}
              <span>{calculateAgeInYearsAndMonths(attendee?.birthDate as string)}</span>
            </Box>
          ))}
        </Stack>

        <Text fw={500} size="md" c={theme.colors.blue[8]} mb="md">
          Do you still want to book this class?
        </Text>

        <Group justify="center" mt={24}>
          <Button
            onClick={async () => {
              trackAction(Actions.AGE_RANGE_OK);
              await handleConfirmAttendees();
              onClose();
            }}
            size="md"
            radius="lg"
            fullWidth={isMobile}
            className={classes.pinkButton}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              trackAction(Actions.AGE_RANGE_CANCEL);
              onClose();
            }}
            size="md"
            radius="lg"
            fullWidth={isMobile}
            className={classes.outlinePinkButton}
          >
            Cancel
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

export default AgeCheckModal;
