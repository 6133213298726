import { Modal, Button, Text, Stack, Group } from '@mantine/core';
import classes from './PonchoModal.module.scss';
import { Actions, trackAction } from 'utils/amplitude';
import { CheckoutPaymentMethodEnum } from 'enums';
import { PonchoPay } from '@icons';
import { Info } from '@phosphor-icons/react';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';

interface IPonchoModal {
  handleCheckout(val: CheckoutPaymentMethodEnum): void;
  supplierName: string;
}

const PonchoModal: React.FC<IPonchoModal> = ({ handleCheckout, supplierName }) => {
  const { openPonchoModal, setOpenPonchoModal } = useCheckoutDetailsContext();
  const onClose = () => {
    setOpenPonchoModal(false);
  };
  return (
    <Modal
      zIndex={999999}
      centered
      size={640}
      opened={openPonchoModal}
      onClose={onClose}
      classNames={{
        close: classes.closeButton,
        title: classes.modalTitle,
        header: classes.modalHeader,
        body: classes.modalBody,
      }}
      title="Choose Your Payment Method"
    >
      <>
        <Text mb="sm">{supplierName} has enabled PonchoPay for this activity.</Text>
        <Text>Please select your preferred payment option to proceed to checkout:</Text>
        <Stack justify="center" align="center" my="lg">
          <Button
            size="md"
            radius="xl"
            className={classes.pinkButton}
            color="pink.5"
            fullWidth
            onClick={() => {
              handleCheckout(CheckoutPaymentMethodEnum.STRIPE);
              onClose();
              trackAction(Actions.PAYMENT_MODAL_CARD);
            }}
          >
            Pay by Card
          </Button>
          <Text>OR</Text>
          <Button
            size="md"
            radius="xl"
            className={classes.ponchoPayButton}
            c="pink.5"
            fullWidth
            variant="outline"
            id="ga-proceed-to-payment-ponchopay"
            onClick={() => {
              handleCheckout(CheckoutPaymentMethodEnum.PONCHOPAY);
              onClose();
              trackAction(Actions.PAYMENT_MODAL_PONCHOPAY);
            }}
          >
            Pay with <PonchoPay className={classes.ponchoPayLogo} />
          </Button>
        </Stack>
        <div className={classes.ponchoInfo}>
          <Group mb="sm" gap="xs">
            <Info weight="bold" size={20} />
            <Text fw={700} size="md">
              What is PonchoPay?
            </Text>
          </Group>
          <Text>
            PonchoPay is a secure payment method that allows you to pay using{' '}
            <b>childcare vouchers</b> or through the government <b>tax-free childcare</b> scheme.
          </Text>
        </div>
      </>
    </Modal>
  );
};

export default PonchoModal;
