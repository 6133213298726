import { Button, Text } from '@mantine/core';
import { GreenRectangle } from '@icons';
import classes from './HomepageStartBooking.module.scss';

interface IHomepageStartBookingProps {
  onClick?: () => void;
}

const HomepageStartBooking: React.FC<IHomepageStartBookingProps> = ({ onClick }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.greenRectangle}>
        <GreenRectangle size={30} />
      </div>
      <div>
        <Text component="h3" className={classes.subheading}>
          Try it now
        </Text>
        <Text className={classes.textCopy}>Book an activity for your child within seconds.</Text>
        <Button onClick={onClick} className={classes.nearbyButton}>
          Search Nearby
        </Button>
      </div>
    </div>
  );
};

export default HomepageStartBooking;
