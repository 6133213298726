import { Button, Text } from '@mantine/core';
import Image from 'next/legacy/image';
import { GreenRectangle } from '@icons';
import classes from './HomepagePartnerWithUs.module.scss';

const HomepagePartnerWithUs: React.FC = () => {
  return (
    <div className={classes.wrapper}>
      <div>
        <Text component="h2" className={classes.heading}>
          One platform for all your activities
        </Text>
        <div className={classes.greenRectangle}>
          <GreenRectangle size={30} />
        </div>
      </div>
      <div className={classes.flexRow}>
        <div className={classes.emptyDiv}></div>
        <div className={classes.textWrapper}>
          <Text component="h3" className={classes.subheading}>
            Are you an activity provider? <br /> List with us
          </Text>
          <Text className={classes.textCopy}>
            Get more visibility, manage your listings and business all on one simple platform.
          </Text>
          <Button
            className={classes.button}
            component="a"
            data-testid="link"
            href="https://www.bookpebble.co.uk/activity-suppliers"
            target="_blank"
          >
            Partner With Us
          </Button>
        </div>
        <div className={classes.imageWrapper}>
          <div className={classes.imageContainer}>
            <Image
              src="/provisional_img.png"
              alt="Laptop and phone with Pebble app"
              objectPosition="bottom"
              width={1125}
              height={705}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepagePartnerWithUs;
