import { Button, Card, Center, Stack, Text, useMantineTheme } from '@mantine/core';
import { SadClipboard } from '@icons';
import { trackAction, Actions } from 'utils/amplitude';
import classes from '../index.module.scss';

interface IError {
  sessionId: string | null;
  basketId: string | null;
  name: string | null;
}

export const Error: React.FC<IError> = ({ sessionId, name, basketId }) => {
  const theme = useMantineTheme();

  const emailBody = `Hi!%0D%0DI just tried to complete a Pebble booking and ran into an issue.%0D%0DHere are my booking details:%0D%0DName: ${
    name || ''
  },%0DDate/Time: ${Date()},%0DSession ID: ${sessionId || ''},%0DBasket: ${
    basketId || ''
  }%0D%0DPlease update me as soon as you can to confirm the status of my booking!`;

  return (
    <Center className={classes.fullPageContainer}>
      <Stack align="center" gap="lg">
        <Card className={classes.errorImage} p="xl">
          <SadClipboard />
        </Card>
        <Text size="xl" fw="bold">
          Whoops, it looks like there was a hiccup confirming your booking
        </Text>
        <Text>Please contact our support team for assistance</Text>
        <Button
          variant="filled"
          color="pink"
          size="lg"
          radius={theme.radius.lg}
          component="a"
          href={`mailto:support@bookpebble.co.uk?subject=Issue Completing My Booking&body=${emailBody}`}
          onClick={() => {
            trackAction(Actions.CHECKOUT_ERROR_CONTACT_SUPPORT);
          }}
        >
          Contact support
        </Button>
      </Stack>
    </Center>
  );
};
