import Image from 'next/legacy/image';
import { Modal, ModalProps, Group, Button, Text, Box, ActionIcon } from '@mantine/core';
import { PebbleLogoDarkBlue } from 'components/ui';
import classes from './CreateAccountModal.module.scss';
import MotherAndChild from '../../../public/mobile_account_modal.png';
import { trackAction, Actions } from 'utils/amplitude';
import { useBasket } from 'context/BasketContext';
import { X } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useMediaQuery } from '@mantine/hooks';

interface ICreateAccountModal extends ModalProps {
  onClose(): void;
  handleCreateAccountModalClick(routeName: string, ticketId?: string): void;
}

const CreateAccountModal: React.FC<ICreateAccountModal> = ({
  onClose,
  handleCreateAccountModalClick,
  ...modalProps
}) => {
  const guest = 'guest';
  const login = 'login';
  const register = 'register';

  const {
    setShowCreateAccountModal,
    setShowTicketTypesModal,
    selectedTickets,
    trialsOnBlocksSelected,
  } = useBasket();

  const isLoggedInOptionOnly = trialsOnBlocksSelected;

  const isMobile = useMediaQuery('(max-width: 768px)', true);

  return (
    <Modal
      {...modalProps}
      size={875}
      classNames={{
        content: classes.modal,
        body: classes.modalBody,
      }}
      padding={0}
      onClose={onClose}
      centered
      zIndex={202}
    >
      <Group align="stretch" className={classes.container}>
        <ActionIcon
          onClick={onClose}
          className={classes.closeButton}
          variant={'filled'}
          aria-label="close-button"
        >
          <X
            size={20}
            color={'white'}
            weight="bold"
            onClick={() => trackAction(Actions.TICKETS_CLOSE)}
          />
        </ActionIcon>
        <div className={classes.imageWrapper}>
          <div className={classes.womanAndChild}>
            <Image src={MotherAndChild} />
          </div>
        </div>
        <div className={classes.modalContent}>
          <Group justify="center">
            {isLoggedInOptionOnly ? (
              <Text fw={700} maw={isMobile ? '95%' : '80%'}>
                In order to book onto a trial you must have a Pebble account
              </Text>
            ) : (
              <>
                <Button
                  className={classes.primaryButton}
                  aria-label="guest-checkout-button"
                  fullWidth
                  size="md"
                  radius="xl"
                  onClick={() => {
                    trackAction(Actions.GUEST_CHECKOUT_SELECTED);
                    setShowTicketTypesModal(false);
                    setShowCreateAccountModal(false);
                    handleCreateAccountModalClick(guest, selectedTickets?.[0]?.ticketId);
                  }}
                >
                  Checkout as guest
                </Button>
                <Text className={classes.text}>or</Text>
              </>
            )}
            <Button
              className={classNames({
                [classes.primaryButton]: isLoggedInOptionOnly,
                [classes.secondaryButton]: !isLoggedInOptionOnly,
              })}
              variant={isLoggedInOptionOnly ? 'filled' : 'outline'}
              fullWidth={!isLoggedInOptionOnly}
              size="md"
              radius="xl"
              onClick={() => {
                trackAction(Actions.CREATE_AN_ACCOUNT);
                handleCreateAccountModalClick(register, selectedTickets?.[0]?.ticketId);
              }}
            >
              Create an account
            </Button>
          </Group>
          <Box mt="lg">
            <Text>Already have an account?</Text>

            <Button
              aria-label="login-button"
              className={classes.loginLink}
              onClick={() => {
                trackAction(Actions.MARKETPLACE_LOGIN_SELECTED);
                handleCreateAccountModalClick(login, selectedTickets?.[0]?.ticketId);
              }}
            >
              Log in
            </Button>
          </Box>
        </div>
        <div className={classes.logoWrapper}>{<PebbleLogoDarkBlue />}</div>
      </Group>
    </Modal>
  );
};

export default CreateAccountModal;
