import {
  Modal,
  ModalProps,
  Group,
  Text,
  Box,
  ActionIcon,
  Title,
  Notification,
  Divider,
  useMantineTheme,
  Flex,
} from '@mantine/core';
import { Product, SelectedTickets } from 'types';
import { ActivityTicket } from 'interfaces';
import {
  ActivityTypeEnum,
  BlockTrialTypeEnum,
  PricingPolicyEnum,
  SocialProofLabelEnum,
} from 'enums';
import { useMediaQuery } from '@mantine/hooks';
import TicketTypesCard from 'components/TicketTypesCard/TicketTypesCard';
import dayjs from 'dayjs';
import { trackAction, Actions } from 'utils/amplitude';
import { useEffect, useMemo, useState } from 'react';
import { formatPenceToPounds } from 'utils/formatPrice';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useBasket } from 'context/BasketContext';
import classes from './TicketTypesSelectionModal.module.scss';
import classNames from 'classnames';
import { Button } from 'components/ui';
import SelectedSessionsList from './SelectedSessionsList/SelectedSessionsList';
import TrialsOnBlocksInfoBanner from './TrialsOnBlocksInfoBanner/TrialsOnBlocksInfoBanner';
import { ToteSimple, Warning, X } from '@phosphor-icons/react';

dayjs.extend(advancedFormat);

interface ITicketTypesSelectionModal extends ModalProps {
  onClose(): void;
  selectedSession: Product | null;
  handleAddToBasket: (tickets: SelectedTickets[]) => void;
  handleSubscriptionBookNow: (subsTicket?: ActivityTicket) => Promise<void>;
  name?: string;
  selectedTickets: SelectedTickets[];
  setSelectedTickets(value: SelectedTickets[]): void;
  loading: boolean;
  activityType?: ActivityTypeEnum;
  tickets: ActivityTicket[];
  supplierId: string;
  socialProofBadge?: SocialProofLabelEnum | null;
  activityId?: string;
  fromBasket?: boolean;
  supplierName?: string;
  blockTrialType: BlockTrialTypeEnum;
  blockTrialSessionCount: number;
  blockTrialPrice: number;
  handleTrialOnBlockBookNow: (subsTicket?: ActivityTicket) => Promise<void>;
}

const TicketTypesSelectionModal: React.FC<ITicketTypesSelectionModal> = ({
  onClose,
  selectedSession,
  handleAddToBasket,
  handleSubscriptionBookNow,
  name,
  selectedTickets,
  setSelectedTickets,
  loading,
  activityType,
  tickets,
  supplierId,
  socialProofBadge,
  activityId,
  supplierName,
  //   flag to determine if the modal is being rendered from the basket popup
  fromBasket,
  blockTrialType,
  blockTrialSessionCount,
  blockTrialPrice,
  handleTrialOnBlockBookNow,
  ...modalProps
}) => {
  const theme = useMantineTheme();
  const {
    basketItems,
    maximumTicketQuantity: basketMaximumTicketQuantity,
    activityDetails,
    selectedClass,
    trialsOnBlocksSelected,
  } = useBasket();
  const isSubscription = activityType === ActivityTypeEnum.SUBSCRIPTION;
  const isMobile = useMediaQuery('(max-width: 768px)', true);

  const [totalTicketsQuantity, setTicketsTotalQuantity] = useState<number>(0);
  const [noSpacesLeft, setNoSpacesLeft] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(noSpacesLeft);
  const [showMaxTicketsBanner, setShowMaxTicketsBanner] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const hideEndTimes = activityDetails?.activity.hideEndTimes;

  const onAddTicket = (ticketId: string) => {
    const selectedTicket = tickets.find((t) => t.id === ticketId);
    if (!selectedTicket) {
      return;
    }
    const newTicket: SelectedTickets = {
      ticketId: selectedTicket.id,
      name: selectedTicket.name,
      ticketPrice: selectedTicket.price,
      capacity: selectedTicket.capacity,
      pricingPolicy: selectedTicket.pricingPolicy,
    };
    const newTickets = [...selectedTickets, newTicket];
    setSelectedTickets(newTickets);
  };

  const onRemoveTicket = (ticketId: string) => {
    const ticketIndexToRemove = selectedTickets.findIndex((t) => t.ticketId === ticketId);
    if (ticketIndexToRemove < 0) {
      return;
    }
    const copyOfSelectedTickets = [...selectedTickets];
    copyOfSelectedTickets.splice(ticketIndexToRemove, 1);
    setSelectedTickets(copyOfSelectedTickets);
  };

  const getTotalPrice = () => {
    if (!selectedTickets || !selectedSession) return;

    const multiplier =
      selectedSession?.singleSessionDate && basketItems && basketItems.length > 0
        ? basketItems.length
        : selectedSession?.numberOfSessionsAvailable;

    const calcPrice = (ticket: SelectedTickets) => {
      if (ticket.pricingPolicy === PricingPolicyEnum.BLOCK_FULL_PRICE) {
        return ticket.ticketPrice;
      }
      return ticket.ticketPrice * multiplier;
    };
    const totalTickets = selectedTickets.reduce(
      (total: number | null, ticket) => Number(total) + calcPrice(ticket),
      null,
    );

    if (!totalTickets && totalTickets !== 0) return '£0.00';

    return totalTickets === 0 ? '' : formatPenceToPounds(totalTickets);
  };

  const getTicketCount = (ticketId: string): number => {
    const matchingTickets = selectedTickets.filter((t) => t.ticketId === ticketId);
    return matchingTickets.length;
  };

  const ticketHasSpaces = (ticketCapacity: number): boolean => {
    const spotsLeft = selectedSession?.spotsLeft || 0;
    return totalTicketsQuantity + ticketCapacity > spotsLeft;
  };

  //Filter free tickets when Trials on blocks selected and sort
  const sortedTickets = useMemo(() => {
    if (trialsOnBlocksSelected) {
      return tickets
        .filter((ticket) => ticket.pricingPolicy !== PricingPolicyEnum.FREE)
        .sort((a, b) => Number(b.isAvailable) - Number(a.isAvailable));
    }
    return tickets.sort((a, b) => Number(b.isAvailable) - Number(a.isAvailable));
  }, [tickets, trialsOnBlocksSelected]);

  useEffect(() => {
    if (selectedTickets) {
      const totalQuantity = selectedTickets.reduce((accum, curr) => accum + curr.capacity, 0);
      setTicketsTotalQuantity(totalQuantity);
    }
  }, [selectedTickets, totalTicketsQuantity, setTicketsTotalQuantity]);

  useEffect(() => {
    if (!selectedSession) return;
    if (totalTicketsQuantity >= selectedSession?.spotsLeft) {
      setNoSpacesLeft(true);
      setShowModal(true);
    } else {
      setNoSpacesLeft(false);
      setShowModal(false);
    }
  }, [totalTicketsQuantity, selectedSession]);

  //   Handle displaying max. tickets react banner
  useEffect(() => {
    if (basketMaximumTicketQuantity && totalTicketsQuantity >= basketMaximumTicketQuantity) {
      setNoSpacesLeft(true);
      setShowMaxTicketsBanner(true);
    } else {
      setNoSpacesLeft(false);
      setShowMaxTicketsBanner(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalTicketsQuantity, basketMaximumTicketQuantity]);

  return (
    <>
      <Modal
        {...modalProps}
        size={1076}
        classNames={{
          root: classes.modal,
          body: classes.modalBody,
          content: classes.modalBodyContent,
          inner: classes.modalInner,
        }}
        centered
        padding={0}
        onClose={onClose}
        fullScreen={isMobile}
        lockScroll={true}
      >
        <Group align="stretch" className={classes.container} wrap={'nowrap'}>
          <ActionIcon
            onClick={onClose}
            className={classes.closeButton}
            variant={isMobile ? 'transparent' : 'filled'}
            aria-label="close-button"
          >
            <X
              size={20}
              color={isMobile ? theme.colors.gray[5] : 'white'}
              weight="bold"
              onClick={() => trackAction(Actions.TICKETS_CLOSE)}
            />
          </ActionIcon>

          <div className={classes.modalContent}>
            <Box
              component="section"
              className={classes.activityHeader}
              aria-label="activity header"
            >
              {!isSubscription && (
                <Title order={1} mb={8}>
                  {supplierName}
                </Title>
              )}
              <Title size="24px" order={2}>
                {name}
              </Title>
            </Box>

            <Divider mt={8} ml={isMobile ? 24 : 0} mr={isMobile ? 24 : 24} />

            {trialsOnBlocksSelected && isMobile && (
              <TrialsOnBlocksInfoBanner blockTrialType={blockTrialType} />
            )}

            <Flex
              className={classNames(classes.ticketsWrapper, {
                [classes.ticketsWrapperNoAffix]: isSubscription || trialsOnBlocksSelected,
                [classes.ticketsWrapperNoErrorMassage]: showErrorMessage,
              })}
              aria-label="ticket(s) wrapper"
              direction={isMobile ? 'column' : 'row'}
              gap={isMobile ? 0 : 72}
            >
              <Flex
                maw={isMobile ? 'auto' : 265}
                className={classes.sessionInformation}
                direction={'column'}
                gap={8}
                mt={isMobile ? 8 : 32}
              >
                <Text fw={700} size="14px">
                  {!isSubscription && <>{selectedClass?.name}</>}
                  {isSubscription && selectedSession && selectedSession.weekdays && (
                    <>
                      {selectedSession?.weekdays[0]},{' '}
                      {hideEndTimes
                        ? selectedSession.startTime
                        : `${selectedSession.startTime} - ${selectedSession.endTime}`}
                    </>
                  )}
                </Text>

                <SelectedSessionsList
                  blockTrialType={blockTrialType}
                  blockTrialSessionCount={blockTrialSessionCount}
                  hideEndTimes={hideEndTimes}
                  fromBasket={fromBasket}
                  isSubscription={isSubscription}
                  selectedSession={selectedSession}
                />

                {!isSubscription && !isMobile && !trialsOnBlocksSelected && (
                  <div className={classes.bookNowContainer}>
                    <Button
                      leftSection={<ToteSimple size={24} weight="fill" />}
                      fullWidth={false}
                      onClick={() => {
                        if (selectedTickets && selectedTickets.length > 0) {
                          handleAddToBasket(selectedTickets);
                        } else {
                          setShowErrorMessage(true);
                        }
                      }}
                      data-testid="add-to-basket-button"
                      miw={245}
                      className={classes.nextBtn}
                    >
                      <Text fw={700} mr={4}>
                        Add to basket: {getTotalPrice()}
                      </Text>
                    </Button>

                    {showErrorMessage && (
                      <Text m={0} size="sm" pt={4} c={theme.colors.red[6]}>
                        Please add ticket(s) to proceed
                      </Text>
                    )}

                    {showModal && !isMobile && !fromBasket && (
                      <Notification
                        classNames={{
                          icon: classes.notificationIcon,
                          root: classes.notificationRoot,
                          title: classes.notificationTitle,
                          closeButton: classes.notificationCloseBtn,
                        }}
                        icon={<Warning size={26} weight="fill" color={theme.colors.yellow[6]} />}
                        onClose={() => {
                          setShowModal(false);
                        }}
                        title="There are no more spaces on this session to book."
                      />
                    )}

                    {showMaxTicketsBanner && !isMobile && fromBasket && (
                      <Notification
                        classNames={{
                          icon: classes.notificationIcon,
                          root: classes.notificationRoot,
                          title: classes.notificationTitle,
                          closeButton: classes.notificationCloseBtn,
                        }}
                        icon={<Warning size={26} weight="fill" color={theme.colors.yellow[6]} />}
                        onClose={() => {
                          setShowMaxTicketsBanner(false);
                        }}
                        title="You've reached the maximum number of tickets you can add."
                      />
                    )}
                  </div>
                )}
              </Flex>

              <Flex
                direction={'column'}
                className={classes.ticketsColumn}
                gap={24}
                mt={isMobile ? 0 : 12}
              >
                <>
                  {trialsOnBlocksSelected && !isMobile && (
                    <TrialsOnBlocksInfoBanner blockTrialType={blockTrialType} />
                  )}
                  {sortedTickets.map((ticket: ActivityTicket) => (
                    <TicketTypesCard
                      isSubscription={isSubscription}
                      selectedSession={selectedSession}
                      ticket={ticket}
                      key={ticket.id}
                      addTicket={onAddTicket}
                      removeTicket={onRemoveTicket}
                      ticketCount={getTicketCount(ticket.id)}
                      noSpacesLeft={ticketHasSpaces(ticket.capacity)}
                      loading={loading}
                      handleSubscriptionBookNow={handleSubscriptionBookNow}
                      blockTrialType={blockTrialType}
                      blockTrialSessionCount={blockTrialSessionCount}
                      blockTrialPrice={blockTrialPrice}
                      handleTrialOnBlockBookNow={handleTrialOnBlockBookNow}
                      setShowErrorMessage={setShowErrorMessage}
                    />
                  ))}
                </>
              </Flex>
              {!isSubscription && isMobile && !trialsOnBlocksSelected && (
                <Group gap={0}>
                  {showModal && !fromBasket && (
                    <Notification
                      classNames={{
                        icon: classes.notificationIcon,
                        root: classes.notificationRoot,
                        title: classes.notificationTitle,
                        closeButton: classes.notificationCloseBtn,
                      }}
                      radius={0}
                      icon={<Warning size={26} weight="fill" color={theme.colors.yellow[6]} />}
                      onClose={() => {
                        setShowModal(false);
                      }}
                      title="There are no more spaces on this session to book."
                    />
                  )}

                  {showMaxTicketsBanner && fromBasket && (
                    <Notification
                      classNames={{
                        icon: classes.notificationIcon,
                        root: classes.notificationRoot,
                        title: classes.notificationTitle,
                        closeButton: classes.notificationCloseBtn,
                      }}
                      radius={0}
                      icon={<Warning size={26} weight="fill" color={theme.colors.yellow[6]} />}
                      onClose={() => {
                        setShowMaxTicketsBanner(false);
                      }}
                      title="You've reached the maximum number of tickets you can add."
                    />
                  )}

                  <div className={classes.bookNowContainer}>
                    <Button
                      mb={isMobile ? 'md' : 0}
                      fullWidth={true}
                      leftSection={<ToteSimple size={24} weight="fill" />}
                      onClick={() => {
                        if (selectedTickets && selectedTickets.length > 0) {
                          handleAddToBasket(selectedTickets);
                        } else {
                          setShowErrorMessage(true);
                        }
                      }}
                      data-testid="add-to-basket-button"
                    >
                      Add to basket: {getTotalPrice()}
                    </Button>
                    {showErrorMessage && (
                      <Text m={0} size="sm" c={theme.colors.red[6]} ta="center">
                        Please add ticket(s) to proceed
                      </Text>
                    )}
                  </div>
                </Group>
              )}
            </Flex>
          </div>
        </Group>
      </Modal>
    </>
  );
};

export default TicketTypesSelectionModal;
